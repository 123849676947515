<template>
  <div>
    <div class="loginBox">
        <el-form label-position="top" :model="userForm" ref="userForm" :rules="rules">
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="userForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="userForm.password"></el-input>
          </el-form-item>
        </el-form>
      <div class="loginBtn">
        <el-button type="primary" class="buttonClass" @click="submitForm('userForm')" :loading="loading">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      userForm: {
        mobile: '',
        password: ''
      },
      rules: {
        mobile: [{ message: '请输入手机号码', trigger: 'blur', required: true }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loading: false
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$api.login(this.userForm).then(res => {
            this.loading = false
            this.$message({
              message: '登录账号成功',
              type: 'success'
            })
            localStorage.setItem('userInfo', JSON.stringify(res.userinfo))
            localStorage.setItem('token', res.userinfo.token)
            this.userForm.mobile = ''
            this.userForm.password = ''
            setTimeout(() => {
              this.$router.push('/home')
            }, 1000)
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .loginBox {
        width: 600px;
        height: 200px;
        position: fixed;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
    }
    .loginBtn {
      margin-top: 80px;
      text-align: center;
    }
    .buttonClass {
        width: 360px;
        height: 50px;
    }
</style>
